import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <h1 className="coming-soon-text">Coming Soon</h1>
        </div>
    );
};

export default ComingSoon;