import { createSlice } from '@reduxjs/toolkit'

export const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        value: '',
        role: '',
        userId:''
    },
    reducers: {
        setter: (state, action) => {
            state.value = action.payload;
            state.role = action.payload.role;
            state.role = action.payload.userId;
        }
    },
})

export const { setter } = tokenSlice.actions

export default tokenSlice.reducer
