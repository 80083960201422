import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, FormControl, Select, MenuItem
} from "@mui/material";
import { useSelector } from "react-redux";

const AllUserInfo = () => {
    const token = useSelector((state) => state.token.value);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch('/user/get-all-users', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }

                const data = await response.json();
                setUserList(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchCustomers();
    }, []);

    return (
        <Box className="box-background">
            <Paper sx={{ padding: 3, marginBottom: 2 }}>
                <Typography variant="h4" gutterBottom sx={{ color: 'red', fontWeight: 'bold' }}>
                    İstifadəçilər
                </Typography>
            </Paper>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>User ID</strong></TableCell>
                            <TableCell><strong>First Name</strong></TableCell>
                            <TableCell><strong>Last Name</strong></TableCell>
                            <TableCell><strong>Email</strong></TableCell>
                            <TableCell><strong>Phone</strong></TableCell>
                            <TableCell><strong>Role</strong></TableCell>
                            <TableCell><strong>Created At</strong></TableCell>
                            <TableCell><strong>Updated At</strong></TableCell>
                            <TableCell><strong>Onay Durumu</strong></TableCell>
                            <TableCell>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userList.map((user) => (
                            <TableRow key={user.userId}>
                                <TableCell>{user.userId}</TableCell>
                                <TableCell>{user.firstName}</TableCell>
                                <TableCell>{user.lastName}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.phone || 'N/A'}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell>{new Date(user.createdAt).toLocaleString()}</TableCell>
                                <TableCell>{new Date(user.updatedAt).toLocaleString()}</TableCell>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <Select
                                            // className={order.orderStatus === `ORDER_NOT_ASSIGNED` ? `ORDER_NOT_ASSIGNED` : order.orderStatus === `ORDER_ASSIGNED` ? `ORDER_ASSIGNED` : `ORDER_DELIVERED` }
                                            value="ORDER_NOT_ASSIGNED"
                                            // onChange={(e) => handleOrderStatusChange(order.orderId, e.target.value)}
                                        >
                                            <MenuItem value="ORDER_NOT_ASSIGNED">Onaylanmis</MenuItem>
                                            <MenuItem value="ORDER_ASSIGNED">Onaylanmamis</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <button
                                        // className={orderStatusChanges[order.orderId] ? `qapinda-button` : `qapinda-button-second`}
                                        // style={{
                                        //     width: "100%",
                                        //     cursor: !orderStatusChanges[order.orderId] ? 'not-allowed' : 'pointer' // Cursor tıklanamaz olduğunda değişiyor
                                        //}}
                                        // onClick={() => handleUpdateClick(order.orderId)}
                                        // disabled={!orderStatusChanges[order.orderId]}
                                    >
                                        Güncelle
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AllUserInfo;
