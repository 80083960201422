import React, { useState } from 'react';
import {Box, TextField, Button, Card, CardContent, Typography, Grid, Paper} from '@mui/material';
import CustomerList from "./CustomerList";
import AddCustomer from "./AddCustomer";
import AddPackage from "./AddPackage";

const AddCustomerAndCreatePackage = () => {
    const [activeButton, setActiveButton] = useState('Partnerler');


    console.log("activeButton: ", activeButton)





    const handleButtonClick = (buttonLabel, contactType) => {
        setActiveButton(buttonLabel);
        // fetchContactMessages(contactType); // Belirli bir contactType'a göre mesajları çek
    };

    return (
        <Box className="box-background">
            <Paper sx={{ padding: 3, marginBottom: 2 }}>
                <Typography variant="h4" gutterBottom sx={{ color: 'red', fontWeight: 'bold' }}>
                    Partnyor
                </Typography>

                <Grid item xs={12} className="ordered-list-tablo-list-choose-button">
                    <button
                        className={`toggle-button-list ${activeButton === 'Partnerler' ? 'active' : ''}`}
                        style={{width:"100%", height:"100%"}}
                        onClick={() => handleButtonClick('Partnerler')}
                    >
                        Partnerler
                    </button>
                    <button
                        className={`toggle-button-list-middle ${activeButton === 'Partner Ekle' ? 'active' : ''}`}
                        style={{width:"100%", height:"100%"}}
                        onClick={() => handleButtonClick('Partnyor Ekle')}
                    >
                        Partnyor Ekle
                    </button>
                    <button
                        className={`toggle-button-list ${activeButton === 'Paket Ekle' ? 'active' : ''}`}
                        style={{width:"100%", height:"100%"}}
                        onClick={() => handleButtonClick('Paket Ekle')}
                    >
                        Paket Ekle
                    </button>
                </Grid>

                <Grid>
                    {activeButton === "Partnerler" ?
                        <CustomerList/> : activeButton === "Partnyor Ekle" ? <AddCustomer/> : <AddPackage/>}
                </Grid>
            </Paper>

        </Box>
    );
};

export default AddCustomerAndCreatePackage;