import React, {useEffect, useState} from 'react';
import {Box, Grid, Paper, Typography, TextField, Button, Collapse,IconButton} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChooseAddressFromMap from "../../../../component/map/ChooseAddressFromMap";
import AddIcon from "@mui/icons-material/Add";
import {useSelector} from "react-redux";
import {getFetch, postFetch} from "../../../../network";

const Branches = () => {
    const token = useSelector((state) => state.token.value)
    const [addNewBranch, setAddNewBranch] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [expanded, setExpanded] = useState(null);

    const userId= token?.userId;

    const [formData, setFormData] = useState({
        branchName: '',
        branchAddress: '',
        branchNumber: '',
    });



    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddressSelectFrom = (address) => {
        setFormData(prevData => ({
            ...prevData,
            branchAddress: address
        }));
    };

    const handleExpandClick = (id) => {
        setExpanded(expanded === id ? null : id);
    };

    const handleDelete = (id) => {
        fetch(`/branch/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to delete branch");
                }
                return response.json();
            })
            .then(() => {
                // Şubeyi başarılı şekilde sildikten sonra listeyi güncelle
                setBranchList(branchList.filter(branch => branch.branchId !== id));
            })
            .catch(error => {
                console.error("Error deleting branch:", error);
            })
            .finally(fetchBranches);
    };
    const handleUpdate = (id) => {
        const updatedBranch = branchList.find(branch => branch.branchId === id); // Güncellenen şubeyi bul

        fetch(`/branch/update?branchId=${id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            body: JSON.stringify({
                branchName: updatedBranch.branchName,
                branchNumber: updatedBranch.branchNumber,
                branchAddress: updatedBranch.branchAddress
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to update branch");
                }
                return response.json();
            })
            .then(data => {
                console.log("Branch updated successfully:", data);
                fetchBranches(); // Şubeleri yeniden yükle
            })
            .catch(error => {
                console.error("Error updating branch:", error);
            });
    };

    const handleChangeEditForm = (e, id) => {
        const { name, value } = e.target;
        setBranchList(prevList =>
            prevList.map(branch =>
                branch.branchId === id ? { ...branch, [name]: value } : branch
            )
        );
    };

    const handleSaveNewBranch = () => {
        console.log("save")
        fetch(`/branch/save?userId=${userId}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Branch save failed");
                }
                return response.json();
            })
            .then(data => {
                console.log("Branch saved successfully:", data);
            })
            .catch(error => {
                console.error("Error saving branch:", error);
            })
            .finally(
                fetchBranches
            );
    }

    const fetchBranches = async () => {
        try {
            const response = await fetch(`/branch/get?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token.token}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch branches");
            }

            const data = await response.json(); // Veriyi JSON formatında alıyoruz
            setBranchList(data); // Şubeleri state'e kaydediyoruz
        } catch (error) {
            console.error("Error fetching branches:", error);
        }
    };

    useEffect(() => {
        fetchBranches();
    }, [userId, token]);


    const handleCanceleNewBranch = () => {
        setFormData("")
        setAddNewBranch(false)
    }


    return (
        <Box className="box-background">
            <Paper sx={{
                padding: 2,
                height: 'auto',
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                border: '2px solid #F4F1DE',
                color: '#000000',
                marginBottom: '20px'
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "20px"
                }} onClick={() => setAddNewBranch(!addNewBranch)}>
                    <Typography variant="h6" component="div">
                        Yeni Şube ekle
                    </Typography>
                    <AddIcon sx={{color: 'red'}}/>
                </div>
                <Collapse in={addNewBranch}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Şube Adı"
                                name="branchName"
                                onChange={handleChange}
                                style={{paddingBottom: "10px"}}
                                required
                            />
                            <TextField
                                fullWidth
                                label="Nömre"
                                type={"number"}
                                name="branchNumber"
                                onChange={handleChange}
                                style={{paddingBottom: "10px"}}
                                required
                            />
                            <ChooseAddressFromMap onAddressSelect={handleAddressSelectFrom} label="Şube Adresi"/>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <button className="qapinda-button"
                                        onClick={handleSaveNewBranch} style={{marginTop: '20px', width:"100%"}}>
                                    Kaydet
                                </button>

                                <button className="qapinda-button-second"
                                        onClick={handleCanceleNewBranch} style={{marginTop: '20px', marginLeft: "15px", width:"100%"}}>
                                    Iptal
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </Collapse>
            </Paper>

            <Paper sx={{
                padding: 2,
                height: 'auto',
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                border: '2px solid #F4F1DE',
                color: '#000000'
            }}>
                <Typography variant="h6" component="div" style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "20px"
                }}>
                    Şubeler
                </Typography>
                {branchList.length > 0 ? branchList.map((branch) => (
                    <div key={branch.branchId} style={{ margin: '2px 2px 15px 2px', border: '1px solid #ccc', borderRadius: '4px', padding: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography style={{ cursor: 'pointer' }} onClick={() => handleExpandClick(branch.branchId)}>
                                {branch.branchName}
                            </Typography>
                            <IconButton
                                onClick={() => handleExpandClick(branch.branchId)}
                                aria-expanded={expanded === branch.branchId}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                        <Collapse in={expanded === branch.branchId}>
                            <TextField
                                label="Şube"
                                name="branchName"
                                value={branch.branchName}
                                onChange={(e) => handleChangeEditForm(e, branch.branchId)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Numara"
                                name="branchNumber"
                                value={branch.branchNumber}
                                onChange={(e) => handleChangeEditForm(e, branch.branchId)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Adres"
                                name="branchAddress"
                                value={branch.branchAddress}
                                onChange={(e) => handleChangeEditForm(e, branch.branchId)}
                                fullWidth
                                margin="normal"
                            />
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <button className="qapinda-button"
                                        style={{ width:"100%"}}
                                        onClick={() => handleUpdate(branch.branchId)}
                                        >Güncelle
                                </button>
                                <button className="qapinda-button-second" style={{ marginLeft: "10px", width:"100%"}} onClick={() => handleDelete(branch.branchId)}
                                        >Sil
                                </button>
                            </div>
                        </Collapse>
                    </div>
                )) : <div style={{color:"black", fontWeight:"bold"}}>Girilen Sube bilginiz yok. Lutfen sube girin. Bu islem sifaris olustururken daha hizli olusturmaniza yardimci olucak.</div>}
            </Paper>
        </Box>
    );
};

export default Branches;