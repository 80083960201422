import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    Grid
} from '@mui/material';
import { useSelector } from 'react-redux';
import "./contactUsAdmin.css";

const ContactUsTable = () => {
    const token = useSelector((state) => state.token.value);
    const [activeButton, setActiveButton] = useState('Hamısı');
    const [contactMessages, setContactMessages] = useState([]); // Tüm mesajlar
    const [filterStatus, setFilterStatus] = useState(""); // Statüyü yönetmek için state
    const [filteredMessages, setFilteredMessages] = useState([]); // Filtrelenmiş mesajlar

    // Tüm mesajları fetch ile çekme, contactType'a göre filtreleme
    const fetchContactMessages = async (type = '') => {
        try {
            const response = await fetch(`/contact-us/get-all${type ? `?contactType=${type}` : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.token}`
                }
            });

            if (!response.ok) {
                throw new Error('Mesajları çekerken hata oluştu.');
            }

            const data = await response.json();
            setContactMessages(data); // Tüm mesajları kaydet
            setFilteredMessages(data); // Filtrelenmiş mesajları da başta tüm mesajlar olarak ayarla
        } catch (error) {
            console.error('Mesajları çekerken hata oluştu:', error);
        }
    };

    useEffect(() => {
        fetchContactMessages(); // Tüm mesajları yükle
    }, []);

    // Butonlara göre filtreleme
    const handleButtonClick = (buttonLabel, contactType) => {
        setActiveButton(buttonLabel);
        fetchContactMessages(contactType); // Belirli bir contactType'a göre mesajları çek
    };

    // Durum güncelleme fonksiyonu
    const handleStatusChange = (id, status) => {
        setFilteredMessages((prevMessages) =>
            prevMessages.map((message) =>
                message.contactId === id ? { ...message, status } : message
            )
        );
    };

    // Statüye göre mesajları filtreleme
    const handleFilterChange = (event) => {
        const status = event.target.value;
        setFilterStatus(status);

        if (status) {
            // Eğer belirli bir statü seçildiyse, statüye göre filtrele
            const filtered = contactMessages.filter(message => message.status === status);
            setFilteredMessages(filtered);
        } else {
            // Eğer "Tümü" seçildiyse, tüm mesajları geri yükle
            setFilteredMessages(contactMessages);
        }
    };

    // Durum güncelleme butonuna basıldığında API'ye update işlemi
    const handleUpdate = async (id) => {
        const messageToUpdate = filteredMessages.find((msg) => msg.contactId === id);

        try {
            const response = await fetch(`/contact-us/update-status/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.token}`
                },
                body: JSON.stringify({
                    messageStatus: messageToUpdate.status
                })
            });

            if (!response.ok) {
                throw new Error('Mesaj durumu güncellenirken hata oluştu.');
            }

            const data = await response.json();
            console.log('Durum başarıyla güncellendi:', data);
        } catch (error) {
            console.error('Durum güncellenirken hata oluştu:', error);
        }
    };

    return (
        <Box sx={{ padding: 3, backgroundColor: '#F4F1DE', minHeight: '100vh' }}>
            <Paper sx={{ padding: 3, marginBottom: 2 }}>
                <Typography variant="h4" gutterBottom sx={{ color: 'red', fontWeight: 'bold' }}>
                    Mesajlar Listesi
                </Typography>

                <Grid item xs={12} className="ordered-list-tablo-list-choose-button">
                    <button
                        className={`toggle-button-list ${activeButton === 'Hamısı' ? 'active' : ''}`}
                        style={{width:"100%", height:"100%"}}
                        onClick={() => handleButtonClick('Hamısı', '')}
                    >
                        Hamısı
                    </button>
                    <button
                        className={`toggle-button-list-middle ${activeButton === 'Bizimlə Əlaqə' ? 'active' : ''}`}
                        style={{width:"100%", height:"100%"}}
                        onClick={() => handleButtonClick('Bizimlə Əlaqə', 'CONTACTUS')}
                    >
                        Bizimlə Əlaqə
                    </button>
                    <button
                        className={`toggle-button-list-middle ${activeButton === 'Kuryer Ol' ? 'active' : ''}`}
                        style={{width:"100%", height:"100%"}}
                        onClick={() => handleButtonClick('Kuryer Ol', 'CAREED')}
                    >
                        Kuryer Ol
                    </button>
                    <button
                        className={`toggle-button ${activeButton === 'Partnyor Ol' ? 'active' : ''}`}
                        style={{width:"100%", height:"100%"}}
                        onClick={() => handleButtonClick('Partnyor Ol', 'PARTNER')}
                    >
                        Partnyor Ol
                    </button>
                </Grid>

                {/* Statüye göre filtreleme işlemi */}
                <Select
                    fullWidth
                    value={filterStatus}
                    onChange={handleFilterChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="">Hamısı</MenuItem>
                    <MenuItem value="UNANSWERED">Cevaplanmadı</MenuItem>
                    <MenuItem value="ANSWERED">Cevaplandı</MenuItem>
                </Select>
            </Paper>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="strong-title">Adı</TableCell>
                            <TableCell className="strong-title">Telefon</TableCell>
                            <TableCell className="strong-title">Email</TableCell>
                            <TableCell className="strong-title">Mesaj</TableCell>
                            <TableCell className="strong-title">Gönderim Tarihi</TableCell>
                            <TableCell className="strong-title">Durum</TableCell>
                            <TableCell className="strong-title">Güncelle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredMessages?.map((message) => (
                            <TableRow key={message.contactId}>
                                <TableCell className={ message.user === null ? `not-user` : `user`}>{message.name}</TableCell>
                                <TableCell>{message.phone}</TableCell>
                                <TableCell>{message.email}</TableCell>
                                <TableCell>{atob(message.message)}</TableCell> {/* Mesaj base64 çözümleniyor */}
                                <TableCell>{new Date(message.sentAt).toLocaleString()}</TableCell>
                                <TableCell>
                                    <Select
                                        className={message.status === `UNANSWERED` ? `unanswered` : `answered` }
                                        value={message.status}
                                        onChange={(e) => handleStatusChange(message.contactId, e.target.value)}
                                        fullWidth
                                    >
                                        <MenuItem value="UNANSWERED">Cevaplanmadı</MenuItem>
                                        <MenuItem value="ANSWERED">Cevaplandı</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <button
                                        className={message.status === "UNANSWERED" ? `qapinda-button` : `qapinda-button-second`}
                                        onClick={() => handleUpdate(message.contactId)}
                                    >
                                        Güncelle
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ContactUsTable;