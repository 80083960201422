import React from 'react';
import {Grid} from "@mui/material";
import foto from "../../asset/becamePartner.svg";
import {useNavigate} from "react-router-dom";

const SecondPage = () => {
    const navigate = useNavigate();
    return (
        <Grid container spacing={2} style={{width: '100%', height: '70vh' , display:"flex", justifyContent:"center",alignItems:"center"}}>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <img src={foto} alt="" style={{
                    width: "100%",
                    height: "auto",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    transition: "box-shadow 0.3s ease-in-out",
                    background:"#fdfcfa"
                }}/>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} style={{color: "black"}}>
                <div className="first-page-background">
                    <h1 className="first-page-title">
                        Korporativ İş Ortaqlığına Dəvətlisiniz!
                    </h1>
                    <p className="first-page-description">
                        Şirkətinizlə Qapinda arasında bir körpü quraraq, əməliyyatlarınızı sürətləndirin və xərclərinizi azaldın. Xüsusi iş ortaqlığı proqramımız çərçivəsində, korporativ müştərilərimizə xüsusi sərfəli qiymətlər və üstünlüklər təklif edirik. İş proseslərinizi optimallaşdırarkən, bazardakı rəqabət üstünlüyünüzü artırın.
                    </p>
                    <button className="qapinda-button" onClick={() => navigate("/partnyor")}>Partnyor Olun</button>
                </div>
            </Grid>
        </Grid>
    );
};

export default SecondPage;