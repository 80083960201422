import { FC } from 'react';

import { useCanvasContext } from './useCanvas.ts';
import useResponsiveSize from '../design-hook/useResponsiveSize.ts';
import WaveObj from '../design-hook/Vave.ts';

const Wave: FC = () => {
    const { context } = useCanvasContext();
    const { width } = useResponsiveSize();
    const height = 470;
    let frequency = 0.013;
    const waves = {
        frontWave: new WaveObj([0.008, 0.012, 0.004], 'rgb(236, 71, 85, 0.1)'),
        backWave: new WaveObj([0.004, 0.008, 0.001], 'rgb(249, 168, 168, 0.1)'),
    };

    const render = () => {
        context?.clearRect(0, 0, width, height);
        Object.entries(waves).forEach(([, wave]) => {
            wave.draw(context!, width, height, frequency);
        });
        frequency += 0.008;
        requestAnimationFrame(render);
    };
    if (context) render();
    return null;
};

export default Wave;
