import React, {useEffect, useState} from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { TextField } from "@mui/material";

const containerStyle = {
    width: '100%',
    height: '400px'
};

const defaultCenter = {
    lat: 40.409264,
    lng: 49.867092
};

const ChooseAddressFromMap = ({onAddressSelect, label, restaurantAddress}) => {
    const [address, setAddress] = useState('');
    const [center, setCenter] = useState(defaultCenter);

    useEffect(() => {
        setAddress("")
    },[restaurantAddress])

    const handleLocationSelect = (lat, lng) => {
        const latLng = new window.google.maps.LatLng(lat, lng);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setAddress(results[0].formatted_address);
                setCenter(latLng.toJSON()); // Convert LatLng object to literal object
                onAddressSelect(results[0].formatted_address);
            }
        });
    };

    const handleAddressChange = event => {
        const newAddress = event.target.value;
        setAddress(newAddress);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: newAddress }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const location = results[0].geometry.location;
                setCenter(location.toJSON()); // Ensure the location is in the correct format
                onAddressSelect(newAddress);
            }
        });
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyCHpzTMjpJvvluwyzeZNumw0taDxNHBFeE">
            <TextField
                label={label}
                value={address ? address : restaurantAddress}
                onChange={handleAddressChange}
                fullWidth
                style={{paddingBottom:"10px"}}
                required
            />
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onClick={e => handleLocationSelect(e.latLng.lat(), e.latLng.lng())}
            >
                <Marker
                    position={center}
                    draggable={true}
                    onDragEnd={e => handleLocationSelect(e.latLng.lat(), e.latLng.lng())}
                    onLoad={marker => {}}
                />
            </GoogleMap>
        </LoadScript>
    );
};

export default ChooseAddressFromMap;