import React from 'react';
import {Grid} from "@mui/material";
import foto from "../../asset/cargoBox.jpg";
import {useNavigate} from "react-router-dom";

const ThirdPage = () => {
    const navigate = useNavigate();
    return (
        <Grid container spacing={2} style={{width: '100%', height: '70vh' , display:"flex", justifyContent:"center",alignItems:"center"}}>

            <Grid item xs={12} sm={12} md={6} lg={6} style={{color: "black"}}>
                <div className="first-page-background">
                    <h1 className="first-page-title">
                        Gündəlik Göndərişləriniz Üçün Sürətli və Ekonomik Həll!
                    </h1>
                    <p className="first-page-description">
                        Evdən çıxmadan, gündəlik ehtiyaclarınızı ən sürətli və ən sərfəli qiymətlərlə göndərmək istəyirsiniz? Qapinda ilə qeydiyyatdan keçin, sifarişinizi tez bir zamanda yaradın və kuryemiz qapınıza gəlsin. Vaxtınıza və xərclərinizə qənaət edin, həyatınızı asanlaşdırın!
                    </p>
                    <button className="qapinda-button" onClick={() => navigate("/register")}>Dərhal Qeydiyyatdan Keçin</button>
                </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <img src={foto} alt="" style={{
                    width: "100%",
                    height: "auto",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    transition: "box-shadow 0.3s ease-in-out"
                }}/>
            </Grid>

        </Grid>
    );
};

export default ThirdPage;