import React from 'react';
import foto
    from "../../asset/red-delivery-car-deliver-express-pin-pointer-mark-location-cardboard-boxes-with-bubble-chat-message-delivery-transportation-logistics-concept-white-background-3d-rendering-illustration.png";
import {Grid} from "@mui/material";
import "./index.css"
import {useNavigate} from "react-router-dom";

const FirstPage = ({isMobile}) => {
    const navigate = useNavigate();
    return (
        <>
            {isMobile &&
                <div className="Logo-mobile" onClick={() => navigate("/")}>
                    Qapında
                </div>
            }
            <Grid container spacing={2}
                  style={{
                      width: '100%',
                      height: '60vh',
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                  }}>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{color: "black"}}>
                    <div className="first-page-background">
                        <h1 className="first-page-title">
                            Sürətli, etibarlı və qapınıza qədər çatdırılma
                        </h1>
                        <p className="first-page-description">
                            Qapinda ilə artıq hər şey ayağınıza gəlir!
                            <br/>
                            İstər fərdi, istərsə də korporativ istifadəçi
                            olun,
                            bütün ehtiyaclarınızı dərhal qarşılayırıq.
                            Kurye ehtiyaclarınız üçün biz sizə bir zəng qədər yaxınıq.
                        </p>
                        <button className="qapinda-button" onClick={() => navigate('/contact-us')}>Bizimlə əlaqə
                            saxlayın
                        </button>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <img src={foto} alt="" style={{
                        width: "100%",
                        height: "auto",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        borderRadius: "10px",
                        transition: "box-shadow 0.3s ease-in-out"
                    }}/>
                </Grid>
            </Grid></>
    );
};

export default FirstPage;