import React from 'react';
import UserMain from "./main-page-for-role/UserMain";
import AdminMain from "./main-page-for-role/AdminMain";
import CustomerMain from "./main-page-for-role/CustomerMain";

const Main = ({token, headerStep}) => {
    console.log("token:", token)

    const userRole = token?.role;


    return (
        <div style={{color:"black"}} >
            {
                userRole === "ROLE_USER" ?
                    <UserMain headerStep={headerStep}/> :
                    userRole === "ROLE_ADMIN" ?
                        <AdminMain/> :
                        userRole === "ROLE_CUSTOMER" ?
                            <CustomerMain/> : <div>Error No Role Pls Contact Admin</div>
            }

        </div>
    );
};

export default Main;