import React from 'react';
import {Typography, Paper, Grid} from "@mui/material";

const AddPackage = () => {
    return (
        <Grid item xs={12} >
            <Paper sx={{ padding: 3, marginBottom: 2 }}>
                <Typography variant="h4" gutterBottom sx={{ color: 'Blue', fontWeight: 'bold' }}>
                    Ilkin bir poxa derman ol,ve get km ucun ne qeder pul yazacagimiz tap
                </Typography>
            </Paper>
        </Grid>
    );
};

export default AddPackage;