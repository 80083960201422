import React, {useState, useEffect} from 'react';
import {Box, Paper, Typography, TextField, Avatar, CircularProgress} from "@mui/material";
import useCurrentUserInfo from "../../../../hook/useCurrentUserInfo";
import {useSelector} from "react-redux";

const Profile = () => {
    const token = useSelector((state) => state.token.value);
    const userId = token?.userId;

    // Hook'tan alınan veriler
    const { currentUserInfo, loading, error } = useCurrentUserInfo();

    // Profil düzenleme ve kaydetme modları için state
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
    });
    const [editMode, setEditMode] = useState(false);

    // currentUserInfo boş değilse profileData'ya set edilir
    useEffect(() => {
        if (currentUserInfo) {
            setProfileData({
                firstName: currentUserInfo.firstName || '',
                lastName: currentUserInfo.lastName || '',
                phone: currentUserInfo.phone || '',
                email: currentUserInfo.email || ''
            });
        }
    }, [currentUserInfo]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress /> {/* Yüklenme animasyonu */}
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography color="error">Hata: {error}</Typography>
            </Box>
        );
    }

    // Input alanları için handleChange fonksiyonu
    const handleChange = (e) => {
        const {name, value} = e.target;
        setProfileData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Profili güncelle butonuna tıklandığında editMode açılır
    const handleUpdateProfile = () => {
        setEditMode(true);
    };

    // Profili kaydetme ve backend'e güncelleme isteği
    const handleSaveProfile = async () => {
        setEditMode(false);

        try {
            const response = await fetch(`/user/update?userId=${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token?.token}`
                },
                body: JSON.stringify({
                    firstName: profileData.firstName,
                    lastName: profileData.lastName,
                    phone: profileData.phone
                })
            });

            if (!response.ok) {
                throw new Error('Profile update failed');
            }

            const updatedUser = await response.json();
            console.log("Profile updated successfully:", updatedUser);
            // Gerekirse yeni güncellenmiş kullanıcı verilerini tekrar set edebilirsiniz.
            setProfileData({
                firstName: updatedUser.firstName,
                lastName: updatedUser.lastName,
                phone: updatedUser.phone,
                email: updatedUser.email
            });
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };

    // Düzenleme modunu iptal etme
    const handleCancelEdit = () => {
        setEditMode(false);
        // Orijinal verilere geri dönme işlemi
        setProfileData({
            firstName: currentUserInfo.firstName,
            lastName: currentUserInfo.lastName,
            phone: currentUserInfo.phone,
            email: currentUserInfo.email
        });
    };

    return (
        <Box sx={{
            padding: 2,
            background: '#F4F1DE',
            height: '100vh',
            color: 'black'
        }}>
            <Paper sx={{
                padding: 3,
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                border: '2px solid #F4F1DE',
                margin: 'auto',
                maxWidth: 500
            }}>
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                    <Avatar sx={{
                        bgcolor: 'red',
                        width: '100px',
                        height: '100px',
                    }}>
                        {profileData?.firstName?.charAt(0).toUpperCase()}
                    </Avatar>
                </div>
                <Typography variant="h5" component="h1" sx={{ textAlign: 'center', marginBottom: 3 }}>
                    {profileData.firstName} {profileData.lastName}
                </Typography>
                <form>
                    <TextField
                        label="Ad"
                        name="firstName"
                        value={profileData.firstName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        disabled={!editMode}
                    />
                    <TextField
                        label="Soyad"
                        name="lastName"
                        value={profileData.lastName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        disabled={!editMode}
                    />
                    <TextField
                        label="Telefon Numarası"
                        name="phone"
                        value={profileData.phone}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        disabled={!editMode}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={profileData.email}
                        fullWidth
                        margin="normal"
                        disabled
                    />
                </form>
                {editMode ? (
                    <div style={{ display: "flex" }}>
                        <button
                            style={{ width: "100%" }}
                            className="qapinda-button"
                            onClick={handleSaveProfile}>
                            Kaydet
                        </button>
                        <button
                            style={{ width: "100%", marginLeft: "10px" }}
                            className="qapinda-button-second"
                            onClick={handleCancelEdit}>
                            İptal
                        </button>
                    </div>
                ) : (
                    <button
                        style={{ width: "100%" }}
                        className="qapinda-button"
                        onClick={handleUpdateProfile}>
                        Güncelle
                    </button>
                )}
            </Paper>
        </Box>
    );
};

export default Profile;