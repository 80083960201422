import React, {useState} from 'react';
import {Grid, Avatar, Popover, List, ListItem} from "@mui/material";
import HamburgerMenu from "../hamburger-menu/HamburgerMenu";
import {useNavigate} from "react-router-dom";
import "./header.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {useDispatch} from "react-redux";
import {setter} from "../../tokenSlice";

const MobileHeader = ({token, setHeaderStep}) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(setter({ token: null, role: null, userId: null }));
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('userId');
        navigate('/login');
        window.location.reload();
        handleClose();
    };

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2} container justifyContent="flex-start">
                <HamburgerMenu token={token} setHeaderStep={setHeaderStep}/>
            </Grid>

            <Grid item xs={10} container justifyContent="flex-end">
                {!token ?
                    <>
                        <div className="qapinda-button" onClick={() => navigate("/login")}>Giriş</div>
                        <button className="qapinda-button-second" onClick={() => navigate("/partnyor")}
                                style={{marginLeft: "10px"}}>Partnyor ol
                        </button>
                    </>
                    :
                    <><Avatar sx={{bgcolor: 'red'}} onClick={handleClick}>{token.token.charAt(0).toUpperCase()} >{token.token.charAt(0).toUpperCase()}</Avatar>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <List>
                                <ListItem button style={{cursor: "pointer"}} onClick={() => navigate("/profile")}>
                                    <ListItemIcon>
                                        <AccountCircleIcon sx={{color: 'red'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Profil"/>
                                </ListItem>
                                <ListItem button style={{cursor: "pointer"}} onClick={handleLogout}>
                                    <ListItemIcon>
                                        <ExitToAppIcon sx={{color: 'red'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Çıxış"/>
                                </ListItem>
                            </List>
                        </Popover>
                    </>
                }
            </Grid>
        </Grid>
    );
};

export default MobileHeader;