import React from 'react';
import { Box, Typography, Link, Container, Grid } from '@mui/material';

const Footer = () => {
    return (
        <Box sx={{ bgcolor: 'black', color: 'white', py: 3 }}>
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1} borderColor="grey.700">
                            <Typography variant="h6" gutterBottom>
                                Qapında
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                Since 2024
                            </Typography>
                            {/*<Typography variant="subtitle1">*/}
                            {/*    Otelİhtiyac 3.0*/}
                            {/*</Typography>*/}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1} borderColor="grey.700">
                            <Typography variant="h6" gutterBottom>
                                Explore
                            </Typography>
                        </Box>
                        <Box>
                            <Link href="#" color="inherit" sx={{ display: 'block', mt: 1 }}>Hakkımızda</Link>
                            <Link href="#" color="inherit" sx={{ display: 'block' }}></Link>
                            <Link href="#" color="inherit" sx={{ display: 'block' }}></Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>

                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;