import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography, FormControl, Select, MenuItem
} from '@mui/material';
import {useSelector} from "react-redux";
import "./index.css"

const CustomerList = () => {
    const token = useSelector((state) => state.token.value);
    const [customerList, setCustomerList] = useState([]);

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch('/user/get-all-customers', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch customers');
                }

                const data = await response.json();
                setCustomerList(data);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    return (
        <div>
            <Typography variant="h4" component="h2" gutterBottom>
                Customer List
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="strong-title" >User ID</TableCell>
                            <TableCell className="strong-title" >First Name</TableCell>
                            <TableCell className="strong-title" >Last Name</TableCell>
                            <TableCell className="strong-title" >Email</TableCell>
                            <TableCell className="strong-title" >Phone</TableCell>
                            <TableCell className="strong-title" >Role</TableCell>
                            <TableCell className="strong-title" >Created At</TableCell>
                            <TableCell className="strong-title" >Updated At</TableCell>
                            <TableCell className="strong-title" >Paket</TableCell>
                            <TableCell className="strong-title" ></TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customerList.map((customer) => (
                            <TableRow key={customer.userId}>
                                <TableCell>{customer.userId}</TableCell>
                                <TableCell>{customer.firstName}</TableCell>
                                <TableCell>{customer.lastName}</TableCell>
                                <TableCell>{customer.email}</TableCell>
                                <TableCell>{customer.phone}</TableCell>
                                <TableCell>{customer.role}</TableCell>
                                <TableCell>{new Date(customer.createdAt).toLocaleString()}</TableCell>
                                <TableCell>{new Date(customer.updatedAt).toLocaleString()}</TableCell>
                                <TableCell>
                                        <FormControl fullWidth>
                                            <Select
                                                // className={order.orderStatus === `ORDER_NOT_ASSIGNED` ? `ORDER_NOT_ASSIGNED` : order.orderStatus === `ORDER_ASSIGNED` ? `ORDER_ASSIGNED` : `ORDER_DELIVERED` }
                                                value="ORDER_NOT_ASSIGNED"
                                                // onChange={(e) => handleOrderStatusChange(order.orderId, e.target.value)}
                                            >
                                                <MenuItem value="ORDER_NOT_ASSIGNED">Paketi yoxdu</MenuItem>
                                                <MenuItem value="ORDER_ASSIGNED">Paket adi</MenuItem>
                                            </Select>
                                        </FormControl>
                                </TableCell>
                                <TableCell>
                                    <button
                                        // className={orderStatusChanges[order.orderId] ? `qapinda-button` : `qapinda-button-second`}
                                        // style={{
                                        //     width: "100%",
                                        //     cursor: !orderStatusChanges[order.orderId] ? 'not-allowed' : 'pointer' // Cursor tıklanamaz olduğunda değişiyor
                                        //}}
                                        // onClick={() => handleUpdateClick(order.orderId)}
                                        // disabled={!orderStatusChanges[order.orderId]}
                                    >
                                        Güncelle
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default CustomerList;
