import React from 'react';
import {Grid} from "@mui/material";
import "./header.css"
import {useSelector} from "react-redux";
import MobileHeader from "./MobileHeader";
import WebHeader from "./WebHeader";

const Header = ({setHeaderStep, isMobile}) => {

    const token = useSelector((state) => state.token.value);



    return (
        <div className="header-background">
            <Grid container spacing={3}>
                {isMobile ? (
                    <MobileHeader token={token} setHeaderStep={setHeaderStep}/>
                ) : (
                    <WebHeader token={token} setHeaderStep={setHeaderStep}/>
                )}
            </Grid></div>
    );
};

export default Header;