import React from 'react';
import CreateOrder from "../component/create-order/CreateOrder";

const UserMain = ({headerStep}) => {
    return (
        <div>
            { headerStep === "create-order" ? <CreateOrder/>
            : "Daha kodlanmadi"
            }
        </div>
    );
};

export default UserMain;