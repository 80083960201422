import React from 'react';
import { Grid, Typography, Paper, Box } from "@mui/material";

import "./index.css";

// Data for the cards
const data = [
    {
        title: "Sürətli və Təhlükəsiz Çatdırılma",
        description: "Bizimlə bütün çatdırılmalarınız sürətli və təhlükəsiz olaraq təmin edilir. İstər şəhər daxili, istərsə də şəhərlərarası, hər bir bağlamanız ən yaxşı şərtlərlə vaxtında çatdırılır.",
        icon: "🚚" // Local Shipping Icon Emoji
    },
    {
        title: "Canlı İzləmə və Müştəri Geribildirimlərini Takip Et",
        description: "Məhsullarınızın harada olduğunu anında bilin və müştəri rəylərini real vaxt rejimində izləyərək xidmətimizi daha da yaxşılaşdırmaq üçün fikirlərinizi bizə bildirin.",
        icon: "📍" // Track Changes Icon Emoji
    },
    {
        title: "Ən Sərfəli Qiymətlər",
        description: "Bizimlə çalışmaq, keyfiyyətli xidməti ən uyğun qiymətə əldə etmənizi təmin edəcək. Sizə ən münasib qiymətləri təklif etmək üçün həmişə çalışırıq.",
        icon: "💵" // Attach Money Icon Emoji
    },
    {
        title: "Bütün Xərcləri Tək Bir Səhifədən Asanlıqla İzlə",
        description: "Bütün maliyyə xərclərinizi və çatdırılmaları tək bir səhifədən izləyin. Bu sayədə idarəetmə prosesini daha rahat və effektiv hala gətirin.",
        icon: "🗃️" // Assessment Icon Emoji
    }
    ]

const FeatureCard = ({ title, description, icon }) => {
    return (
        <Paper className="fifth-wyh-us-page-paper-background" style={{boxShadow: '0px 4px 8px rgb(255, 117, 121, 0.30)'}}>
            <div className="fifth-wyh-us-page">
                {icon} {title}
            </div>
            <Typography>
                {description}
            </Typography>
        </Paper>
    );
};

const FifthWhyUs = () => {
    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color:"black" }}>
            <div className="fifth-wyh-us-page-why-us-title">Niyə biz ?</div>
            <Grid container spacing={2}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <FeatureCard title={item.title} description={item.description} icon={item.icon} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default FifthWhyUs;