import React from 'react';

const ZRapore = () => {
    return (
        <div>
            Z-raporu
        </div>
    );
};

export default ZRapore;