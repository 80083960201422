import React from 'react';
import {useSelector} from "react-redux";
import AllOrderListForAdmin from "../admin/all-orders/AllOrderListForAdmin";
import OrderedListForCustomer from "../customer/OrderedListForCustomer";

const OrderedList = () => {
    const token = useSelector((state) => state.token.value);
    const userRole = token?.role;
    return (
        <div>
            {
                userRole === "ROLE_USER" ?
                    "" :
                    userRole === "ROLE_ADMIN" ?
                        <AllOrderListForAdmin/> :
                        userRole === "ROLE_CUSTOMER" ?
                            <OrderedListForCustomer/> : <div>Error No Role Pls Contact Admin</div>
            }

        </div>
    );
};

export default OrderedList;