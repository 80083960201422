import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, Paper, Grid } from '@mui/material';
import { useSelector } from "react-redux";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const CustomerMain = () => {
    const token = useSelector((state) => state.token.value);
    const userId = token.userId;
    const [filterOption, setFilterOption] = useState('all');
    const [mainPageData, setMainPageData] = useState([]);

    const fetchOrderStatistics = useCallback(async (filterOption) => {
        if (userId === null) {
            return <div>Hata</div>;
        }

        try {
            const response = await fetch(`/order-calculator/get-user-order-statistics?userId=${userId}&filterOption=${filterOption}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch order statistics');
            }

            const data = await response.json();
            const updatedData = [
                { title: 'Ümumi Sifariş Sayı', value: data.totalOrderCount, Icon: ShoppingCartIcon },
                { title: 'Ümumi Sifariş Qiyməti', value: data.totalOrderPrice, Icon: MonetizationOnIcon },
                { title: 'Tamamlanmış Sifariş Sayı', value: data.completedOrderCount, Icon: CheckCircleOutlineIcon },
                { title: 'Gözləyən Sifariş Sayı', value: data.pendingOrderCount, Icon: HourglassEmptyIcon }
            ];
            setMainPageData(updatedData);
        } catch (error) {
            console.error('Error fetching order statistics:', error);
        }
    }, [token, userId]);

    useEffect(() => {
        fetchOrderStatistics(filterOption);
    }, [fetchOrderStatistics, filterOption]);

    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    return (
        <Box className="box-background">
            <Paper sx={{ padding: 2, marginBottom: 2 }}>
                <Typography variant="h6" sx={{ paddingBottom: 3 }}>Sipariş İstatistikleri</Typography>
                <FormControl fullWidth>
                    <InputLabel>Filtr Seçimi</InputLabel>
                    <Select value={filterOption} onChange={handleFilterChange}>
                        <MenuItem value="daily">Günlük</MenuItem>
                        <MenuItem value="weekly">Həftəlik</MenuItem>
                        <MenuItem value="monthly">Aylıq</MenuItem>
                        <MenuItem value="yearly">İllik</MenuItem>
                        <MenuItem value="all">Hamısı</MenuItem>
                    </Select>
                </FormControl>
            </Paper>

            <Grid container spacing={2}>
                {mainPageData.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper sx={{
                            padding: 2,
                            marginBottom: 0.1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 140,
                            backgroundColor: '#ffffff', /* Kartların arka plan rengi */
                            boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                            border: '2px solid #F4F1DE',
                            color: '#000000' /* Yazı rengi */
                        }}>
                            <item.Icon sx={{ fontSize: 40, marginBottom: 1 }} />
                            <Typography variant="h6" component="div">
                                {item.title}
                            </Typography>
                            <Typography variant="body1">
                                {item.value}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default CustomerMain;
