import React, {useEffect, useState} from 'react';
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Typography,
    Snackbar, Alert
} from '@mui/material';
import ChooseAddressFromMap from "../../../../component/map/ChooseAddressFromMap";
import {useSelector} from "react-redux";

const CreateOrder = () => {
    const token = useSelector((state) => state.token.value)
    const [branchList, setBranchList] = useState([]);
    const userId = token?.userId;
    const [orderNumber, setOrderNumber] = useState("");
    const [orderIdFromShipday, setOrderIdFromShipday] = useState("")
    const [openToast, setOpenToast] = useState(false); // Toast mesajı için state
    const [toastMessage, setToastMessage] = useState(''); // Toast mesaj içeriği
    const [toastSeverity, setToastSeverity] = useState('success'); // Toast mesaj tipi (success or error)
    const [isSubmitting, setIsSubmitting] = useState(false); // Form gönderilirken butonu disable etmek için
    const [formData, setFormData] = useState({

        // kendi DB'im icin
        branch: '',
        orderNumber: '',

        // Sifaris eden
        customerName: '',
        customerAddress: '',
        customerPhoneNumber: '',

        // Bu ise bizimmusterinin branch adresi

        restaurantName: '',
        restaurantAddress: '',
        restaurantPhoneNumber: '',

        //     DB'ye ek bilgi
        speedRecipe: '',
        orderPrice: '',
        paymentMethod: '',
        totalOrderCost: '',
        orderVolume: '',
        orderReceipt: '',
        deliveryInstruction: ''


    });

    const fetchBranches = async () => {
        try {
            const response = await fetch(`/branch/get?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token.token}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch branches");
            }

            const data = await response.json(); // Veriyi JSON formatında alıyoruz
            setBranchList(data); // Şubeleri state'e kaydediyoruz
        } catch (error) {
            console.error("Error fetching branches:", error);
        }
    };

    useEffect(() => {
        fetchBranches();
    }, [userId, token]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        // Şube seçildiğinde ilgili bilgileri otomatik dolduruyoruz
        if (name === 'branch') {
            const selectedBranch = branchList.find(branch => branch.branchName === value);

            if (selectedBranch) {
                setFormData(prevData => ({
                    ...prevData,
                    branch: value,
                    restaurantName: selectedBranch.branchName, // Şubenin adı restaurant ismi olarak
                    restaurantAddress: selectedBranch.branchAddress, // Şubenin adresi
                    restaurantPhoneNumber: selectedBranch.branchNumber // Şubenin telefon numarası
                }));
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };


    const handleAddressSelectFrom = (address, lat, lng) => {
        if (formData.branch) {
            // Eğer bir şube seçilmişse, şubenin adresini formData'dan kullan
            const selectedBranch = branchList.find(branch => branch.branchName === formData.branch);
            if (selectedBranch && selectedBranch.branchAddress) {
                setFormData(prevData => ({
                    ...prevData,
                    restaurantAddress: selectedBranch.branchAddress, // Şubenin adresi otomatik olarak set ediliyor
                    pickupLatitude: lat,
                    pickupLongitude: lng
                }));
            }
        } else {
            // Haritadan manuel bir adres seçildiyse, haritadan gelen adresi kullan
            setFormData(prevData => ({
                ...prevData,
                restaurantAddress: address, // Haritadan gelen adres
                pickupLatitude: lat,
                pickupLongitude: lng
            }));
        }
    };

    const handleAddressSelectTo = (address, lat, lng) => {
        setFormData(prevData => ({
            ...prevData,
            customerAddress: address,
            deliveryLatitude: lat,
            deliveryLongitude: lng
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true); // Form gönderilirken butonu disable yap

        // API isteğini buradan gönderiyoruz.
        const orderData = {
            // orderNumber: "Baku004",

            customerName: formData.customerName,
            customerAddress: formData.customerAddress,
            customerPhoneNumber: formData.customerPhoneNumber,

            restaurantName: formData.restaurantName,
            restaurantAddress: formData.restaurantAddress,
            restaurantPhoneNumber: formData.restaurantPhoneNumber,

            speedRecipe: formData.speedRecipe.toString() + "saat",
            orderPrice: formData.orderPrice,
            orderVolume: formData.orderVolume,
            orderReceipt: formData.orderReceipt,
            paymentMethod: formData.paymentMethod.toLowerCase(),
            totalOrderCost: formData.orderPrice,
            deliveryInstruction: formData.deliveryInstruction
        };

        fetch(`/order/create?userId=${userId}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            body: JSON.stringify(orderData)
        })
            .then(response => {

                if (!response.ok) {
                    throw new Error("Branch save failed");
                }
                return response.json();
            })
            .then(orderNumber => {
                createOrderInShipday(orderData, orderNumber)
                setOrderNumber(orderNumber)
            })
            .catch(error => {
                console.error("Error saving branch:", error);
            })
            .finally(() => {
                // if(orderNumber){
                //     createOrderInShipday(orderData ,orderNumber)
                // }
            })


        // try {
        //     const response = await fetch('https://api.shipday.com/orders/23739660', {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': 'Basic BCD1hOHjzr.DKK1GFpd3UGaLCyDZzcz', // API anahtarınızı buraya ekledik
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify(orderData)
        //     });
        //
        //     const result = await response.json();
        //     console.log("API Response:", result);
        // } catch (error) {
        //     console.error("API Error:", error);
        // }
    };

    function createOrderInShipday(orderData, orderNumber) {
        const orderDataForShipday = {
            ...orderData,
            orderNumber
        };

        fetch('https://api.shipday.com/orders/', {
            method: 'POST',
            headers: {
                'Authorization': 'Basic BCD1hOHjzr.DKK1GFpd3UGaLCyDZzcz', // API anahtarınızı buraya ekledik
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orderDataForShipday)
        })
            .then(response => response.json())
            .then(result => {
                setOrderIdFromShipday(result?.orderId);
                if (result.success === true) {
                    updateOrderIdFromShipdayForDb(orderNumber, result?.orderId);
                    setToastMessage('Mesajınız bizə uğurla çatdırıldı! Yəni sifariş üçün müştəri məlumatlarını yenidən doldurun'); // Başarı mesajı
                    setToastSeverity('success'); // Başarı mesajı rengi (yeşil)
                    setOpenToast(true); // Toast mesajını göster
                } else {
                    setToastMessage('Mesaj göndərilərkən səhv baş verdi. Zəhmət olmasa, yenidən cəhd edin.'); // Hata mesajı
                    setToastSeverity('error'); // Hata mesajı rengi (kırmızı)
                    setOpenToast(true); // Toast mesajını göster
                }
                console.log("API Response:", result);
            })
            .catch(error => {
                console.error("API Error:", error);

            });
    }

    const updateOrderIdFromShipdayForDb = (orderId, orderIdFromShipday) => {
        fetch(`/order/update-shipday-order-id/${orderId}/${orderIdFromShipday}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to update branch");
                }
                setIsSubmitting(false); // Form gönderilirken butonu disable yap
                setFormData(prevData => ({
                    ...prevData,
                    // Sifaris eden
                    customerName: '',
                    customerAddress: '',
                    customerPhoneNumber: '',
                    speedRecipe: '',
                    orderPrice: '',
                    paymentMethod: '',
                    totalOrderCost: '',
                    orderVolume: '',
                    orderReceipt: '',
                    deliveryInstruction: ''
                }));
                return response.json();
            })
            .then(data => {;
                fetchBranches(); // Şubeleri yeniden yükle
            })
            .catch(error => {
                console.error("Error updating branch:", error);
            });
    }

    const handleCloseToast = () => {
        setOpenToast(false);
    };

    const isDisable = () => {
        return !formData.customerName || !formData.customerAddress || !formData.customerPhoneNumber || isSubmitting || !formData.restaurantName || !formData.restaurantAddress || !formData.restaurantPhoneNumber || !formData.orderPrice || !formData.paymentMethod || isSubmitting;
    };

    return (
        <Box className="box-background">
            <Box sx={{
                width: '85%',
                margin: '0 auto',
                padding: '1rem',
                border: '2px solid #F4F1DE',
                borderRadius: '8px',
                background: 'white',
                color: 'black',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
            }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Sifariş Elave Et
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        {/* Şube Seçimi */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="branch-label">Firiyal Melumatlarina gore doldur</InputLabel>
                                <Select
                                    labelId="branch-label"
                                    id="branch-select"
                                    name="branch"
                                    value={formData.branch}
                                    label="Şube Seç"
                                    onChange={handleChange}
                                >

                                    {branchList?.map((branch) => (
                                        <MenuItem key={branch.branchId}
                                                  value={branch.branchName}>{branch.branchName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                label="Ad Soyad"
                                variant="outlined"
                                name="restaurantName"
                                type={"text"}
                                value={formData.restaurantName}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                label="Telefon Nömersi"
                                variant="outlined"
                                name="restaurantPhoneNumber"
                                type={"number"}
                                placeholder={"+994 ile baslamalidir"}
                                value={formData.restaurantPhoneNumber}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <ChooseAddressFromMap onAddressSelect={handleAddressSelectFrom}
                                                  restaurantAddress={formData.restaurantAddress}
                                                  label={"Sizin Ünvanınız"}/>
                        </Grid>
                        {/************************************************************/}
                        <Grid item xs={12}>
                            <ChooseAddressFromMap onAddressSelect={handleAddressSelectTo} restaurantAddress={formData.customerAddress} label={"Müştəri Ünvanı"}/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Müştəri Adı"
                                variant="outlined"
                                name="customerName"
                                value={formData.customerName}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Müştəri Telefonu"
                                variant="outlined"
                                type={"number"}
                                name="customerPhoneNumber"
                                value={formData.customerPhoneNumber}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="speedRecipe-label">Teslimat Süresi</InputLabel>
                                <Select
                                    labelId="speedRecipe-label"
                                    id="speedRecipe-select"
                                    name="speedRecipe"
                                    value={formData.speedRecipe}
                                    label="Teslimat Süresi"
                                    onChange={handleChange}
                                    // required
                                >
                                    <MenuItem value={3}>3 Saat</MenuItem>
                                    <MenuItem value={5}>5 Saat</MenuItem>
                                    <MenuItem value={8}>8 Saat</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Sipariş Fiyatı"
                                variant="outlined"
                                name="orderPrice"
                                type={"number"}
                                value={formData.orderPrice}
                                onChange={handleChange}
                                fullWidth
                                // required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="paymentMethod-label">Ödeme Yöntemi</InputLabel>
                                <Select
                                    labelId="paymentMethod-label"
                                    id="paymentMethod-select"
                                    name="paymentMethod"
                                    value={formData.paymentMethod}
                                    label="Ödeme Yöntemi"
                                    onChange={handleChange}
                                    // required
                                >
                                    <MenuItem value="Cash">Nəğd</MenuItem>
                                    <MenuItem value="Card">Kart</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="orderVolume-label">Sipariş Hacmi</InputLabel>
                                <Select
                                    labelId="orderVolume-label"
                                    id="orderVolume-select"
                                    name="orderVolume"
                                    value={formData.orderVolume}
                                    label="Sipariş Hacmi"
                                    onChange={handleChange}
                                    // required
                                >
                                    <MenuItem value="Small">Küçük</MenuItem>
                                    <MenuItem value="Medium">Orta</MenuItem>
                                    <MenuItem value="Large">Büyük</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Sipariş Çekisi"
                                variant="outlined"
                                name="orderReceipt"
                                type="number"
                                value={formData.orderReceipt}
                                onChange={handleChange}
                                fullWidth
                                // required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Sipariş Detayları"
                                variant="outlined"
                                name="deliveryInstruction"
                                value={formData.deliveryInstruction}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={4}
                                // required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {formData.totalOrderCost}
                        </Grid>

                        <Grid item xs={12}>
                            <button
                                className="qapinda-button" type="submit"
                                style={{
                                    width: "100%",
                                    backgroundColor: isDisable() ? '#ccc' : '#f00', // Disable olduğunda gri, aktif olduğunda kırmızı
                                    cursor: isDisable() ? 'not-allowed' : 'pointer' // Cursor tıklanamaz olduğunda değişiyor
                                }}
                                disabled={isDisable()}
                            >
                                Sifariş Ver
                            </button>
                        </Grid>
                    </Grid>
                </form>

                {/* Toast mesajı */}
                <Snackbar open={openToast} autoHideDuration={3000} onClose={handleCloseToast}>
                    <Alert onClose={handleCloseToast} severity={toastSeverity} sx={{width: '100%'}}>
                        {toastMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}

export default CreateOrder;