import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const useCurrentUserInfo = () => {
    const token = useSelector((state) => state.token.value);
    const userId = token?.userId;
    const [currentUserInfo, setCurrentUserInfo] = useState(null);
    const [loading, setLoading] = useState(true); // Yüklenme durumu
    const [error, setError] = useState(null); // Hata durumu

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                setLoading(true);
                const response = await fetch(`/user/get?userId=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }

                const data = await response.json();
                setCurrentUserInfo(data); // Kullanıcı bilgileri state'e kaydediliyor
            } catch (err) {
                setError(err.message); // Hata durumu kaydediliyor
            } finally {
                setLoading(false); // Yükleme durumu sona erdi
            }
        };

        if (userId && token) {
            fetchUserInfo();
        }
    }, [userId, token]);

    return { currentUserInfo, loading, error };
};

export default useCurrentUserInfo;