import React from 'react';

const Exception = () => {
    return (
        <div style={{color:"black"}}>
            Bi hata Oldu Bizimle iletisime gec
        </div>
    );
};

export default Exception;