import React, {useState} from 'react';
import {Card, CardContent, Grid, TextField, Typography} from "@mui/material";

const AddCustomer = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        role: 'ROLE_CUSTOMER', // Sabit olarak ROLE_CUSTOMER olacak
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Customer data:', formData);
        // Backend'e gönderme işlemi yapılacak
        // fetch('/api/customer', { method: 'POST', body: JSON.stringify(formData) ... })
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    return (
        <Grid item xs={12}  style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Card sx={{ maxWidth: 500, padding: 3, marginTop: 3 }}>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Partnyor Ekle
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="Ad"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Soyad"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Telefon"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Şifre"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                        <button
                            className="qapinda-button"
                            style={{width:"100%"}}
                            type="submit"
                        >
                            Ekle
                        </button>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default AddCustomer;