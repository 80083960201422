import React from 'react';
import {Grid} from "@mui/material";
import foto from "../../asset/becameCourier.svg";
import {useNavigate} from "react-router-dom";

const SixthBecameCourier = () => {
    const navigate = useNavigate();
    return (
        <Grid container spacing={2} style={{width: '100%', height: '60vh' , display:"flex", justifyContent:"center",alignItems:"center", marginTop:"30px"}}>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <img src={foto} alt="" style={{
                    width: "100%",
                    height: "auto",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    transition: "box-shadow 0.3s ease-in-out",
                    background:"#fdfcfa"
                }}/>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} style={{color: "black"}}>
                <div className="first-page-background">
                    <h1 className="first-page-title">
                        Bizimle Islemek isteyirsiz?
                    </h1>
                    <p className="first-page-description">
                        Formu doldurun bizim oz aracinizla veya bizim size saglayacagimiz arac ile calisma sansi elde edin.
                    </p>
                    <button className="qapinda-button" onClick={() => navigate("/courier")}>Kuryerimiz Olun</button>
                </div>
            </Grid>
        </Grid>
    );
};

export default SixthBecameCourier;